<template>
  <div class="subpage">
    <FrameMD
        :posY="posY"
        :transitionPhoto="false"
        :staticPhoto="true"
        :photo="pageContent.section0.photo"
        :photoHeight="'500px'"
        :header="pageContent.section0.header"
        :text="pageContent.section0.text"
    ></FrameMD>
    
    <div>
      <div class="container" v-for="(category, categoryIndex) in categories" :key="categoryIndex">
        <FramePhotos
          :posY="posY"
          :transitionPhoto="true"
          :photo="'/gallery/' + category.path + '/' + category.photo"
          :header="null"
          :text="category.description"
          :reversed="categoryIndex % 2 == 0 ? false : true"
          class="category"
          :ref="'category' + categoryIndex"
          @click.native="showPhotosContainer(categoryIndex)"
        ></FramePhotos>
        <transition name="slide-down">
          <div
            class="photos-container"
            v-show="category.show"
            :style="categoryIndex % 2 == 0 ? {borderLeft: '20px solid #EB7054'} : {borderRight: '20px solid #EB7054'}"
          >
            <div
              v-for="(photo, photoIndex) in sortedPhotos[categoryIndex]" :key="photoIndex"
              @click="openGalleryViewer(categoryIndex, photoIndex)"
              class="photo"
            >
              <img
                :src="preloader"
                :alt="photo.alt"
                :data-src="'gallery/' + category.path + '/' + photo.src"
                :ref="'photos' + categoryIndex"
                width="300"
              >
            </div>
          </div>
        </transition>
      </div>
    </div>

    <transition name="fade">
      <GalleryViewer v-if="galleryViewerVisible" :category="category" :images="propPhotos" :clickedImage="clickedPhoto" @closeGalleryViewer="closeGalleryViewer"></GalleryViewer>
    </transition>

  </div>
</template>

<script>
import FrameMD from '@/components/FrameMD'
import FramePhotos from '@/components/FramePhotos'
import GalleryViewer from '@/components/GalleryViewer'
import { mapState } from 'vuex'

export default {
  name: 'Photos',
  props: ['posY'],
  components: {FrameMD, FramePhotos, GalleryViewer},
  data () {
      return {
        preloader: require('@/assets/photo-preloader.gif'),
        galleryViewerVisible: false,
        category: null,
        propPhotos: [],
        clickedPhoto: null
      }
  },
  computed: {
    ...mapState({
      pageContent: state => state.data.photos
    }),
    categories () {
      return this.$store.getters.photosCategories
    },
    photos () {
      return this.$store.state.photos.photos
    },
    sortedPhotos () {
      let _sortedPhotos = []
      for (let i = 0; i < this.categories.length; i++) {
        _sortedPhotos.push(
          this.photos.filter(el => {
            return el.category === i
          })
        ) 
      }
      return _sortedPhotos
    }
  },
  methods: {
    showPhotosContainer (index) {
      this.$store.dispatch('setPhotosContainerVisibility', {
        index: index,
        show: !this.categories[index].show
      }).then(this.$forceUpdate())
      this.showPhotos(index)
    },
    showPhotos (index) {
      this.$refs['photos' + index].forEach(el => {
        el.src = el.dataset.src
      })
    },
    closeGalleryViewer () {
      this.galleryViewerVisible = false
      document.body.style.overflowY = 'visible'
    },
    openGalleryViewer (categoryIndex, photoIndex) {
      this.category = this.categories[categoryIndex]
      this.clickedPhoto = photoIndex
      this.propPhotos = this.sortedPhotos[categoryIndex]
      this.galleryViewerVisible = true
      document.body.style.overflowY = 'hidden'
    }
  },
  created () {
    for (let i = 0; i < this.categories.length; i++) {
      this.$store.dispatch('setPhotosContainerVisibility', {
        index: i,
        show: false
      })
    }
  }
}

</script>

<style lang="scss" scoped>

.container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 100px auto;
}
.category {
  margin-bottom: 50px;
}
.photos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.photo {
  margin: 10px;
  img {
    transition: all .1s linear;
  }
  @media screen and (min-width: 960px) {
    img:hover {
      transform: scale(1.03);
      box-shadow: 0px 0px 5px 5px #888;
      cursor: pointer;
    }
  }
}

</style>